body, html {
    font-family: 'Lato', 'Helvetica Neue';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

#root .row {
    margin: 0;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#upload-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 70vh;
}

#upload-card {
    margin: auto;
    text-align: center;
    padding: 1rem;
    width: 18rem;
}

.main-container {
    margin: 1rem auto;
    width: 100%;
    max-width: 1920px;
}

.sidebar {
    padding: 2rem;
    margin: 0.5rem;

    border-radius: 1rem;
    box-shadow: #ddd 0 0 1rem;
}

.sidebar > h2 {
    text-align: center;
}

#single-year-container {
    padding: 0.75rem;
}

.analytics-container {
    flex-shrink: 1 !important;
    padding: 1rem;
    margin: 0.5rem;
    
    border-radius: 1rem;
    box-shadow: #ddd 0 0 1rem;
}

.plot {
    /* padding: 1rem; */
    margin: 1rem 0;
    min-width: 300px;
}

.plot-title {
    text-align: center;
    font-size: 1.5rem;
}

#main-header {
    text-align: center;
}

#main-header > h1 {
    margin-top: 2rem;
}

#main-header > hr {
    color: #ddd;
    margin-bottom: 2rem;
}

.info-card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
}

.info-card {
    margin: 1rem;
    padding: 1rem 2rem;
    text-align: center;
    border-radius: 1rem;
    border-width: 0;
    box-shadow: #ddd 0 0 1rem;
}

.info-card .big-num {
    font-size: 3rem;
    font-weight: bold;
}

.footer {
    text-align: center;

    padding: 1rem;

    color: #ddd;
    background-color: rgb(33, 37, 41);
    margin-top: auto;
}

#github-mark {
    margin-bottom: 0.5rem;
    width: 48px;
    height: 48px;
}

#help-container {
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 1rem;
    border-width: 0;
    box-shadow: #ddd 0 0 1rem;
}

#help-container > h1 {
    text-align: center;
}

#help-container > ol {
    font-size: 1.2rem;
}

#help-container > ol > li {
    margin: 0.5rem;
}

.inline-code {
    font-family: 'Courier New' !important;
    color: darkcyan;
    margin-right: auto;
}

.hidden {
    visibility: hidden !important;
}